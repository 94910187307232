<template>
  <div class="content-box setting-box">
    <!-- <v-Header :isShowTitle="true" :showback="true" tittle='设置' @back='$router.go(-1)'>
    </v-Header> -->
    <div v-wechat-title="'设置'"></div>
    <div class='page-content'>
      <div class='setting-box-content'>
        <div class='flex flex-align-items flex-justify-content-between'>
          <div>
            <img src="../assets/imgs/setting_icon1.png" alt=""><span class='common-ml'>检查倒计时</span>
          </div>
          <div>
            <!-- <van-switch v-model="isChecked" @change='handleChange'/> -->
            <el-switch v-model="isChecked" @change='handleChange'>
            </el-switch>
          </div>
        </div>
         <div class='flex flex-align-items flex-justify-content-between' v-show='isChecked'>
          <div>
            <img src="../assets/imgs/setting_icon2.png" alt=""><span class='common-ml'>倒计时时间</span>
          </div>
          <div>
            <el-select v-model="currentTime" placeholder="请选择时间" @change='getTime' class='time-select'>
              <el-option
                v-for="item in $timeList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vHeader from '../components/header.vue'
export default {
  data() {
    return {
      tittle:'',
      currentTime:sessionStorage.getItem('clock')?sessionStorage.getItem('clock'):this.$time,
      isChecked:JSON.parse(sessionStorage.getItem('isInterval'))!=null?JSON.parse(sessionStorage.getItem('isInterval')):this.$isInterval
    }
  },
  components:{
    vHeader
  },
  created(){
    // this.$isInterval = this.isChecked;
    console.log(JSON.parse(sessionStorage.getItem('isInterval')))
  },
  methods: {
    handleChange(val){
      // this.$isInterval = val;
      sessionStorage.setItem('isInterval',val)
      // console.log(this.$isInterval)
    },
    getTime(val){
      sessionStorage.setItem('clock',val)
    }
  },
};
</script>


 
<style lang="less">
@import "../assets/less/index.less";
.setting-box{
  .setting-box-content{
    width: 86%;
    margin: 0 auto;
    .mt(60);
    .padding(40,20,40,20);
    .b-radius(10);
    background: #fff;
    .lh(100);
  }
  .time-select{
    .w(200);
    /deep/.el-input__inner{
      border: 0;
    }
    
  }
  
}
</style>